<div class="container w-100 mw-100">
    <div class="row loader" *ngIf="this.loader">
        <div class="col-md-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>

    <ng-container *ngIf="!this.loader">
        <div class="row justify-content-center my-4">
            <div class="col-5 col-sm-5 col-md-4 col-lg-3">
                <div class="form-group">
                    <input type="date" (change)="this.setFilterValuesIni($event.target.value)" [attr.min]="this.dateMinlimit"
                        class="text-center form-control inputRegister unstyled" id="dateInit" [(ngModel)]="this.dateIni">
                </div>
            </div>
            <div class="col-5 col-sm-5 col-md-4 col-lg-3">
                <div class="form-group">
                    <input type="date" (change)="this.setFilterValuesFin($event.target.value)" [attr.min]="this.dateMinlimit"
                        class="text-center form-control inputRegister" id="dateFin" [(ngModel)]="this.dateFin">
                </div>
            </div>
        </div>

        <div class="row justify-content-center mx-4">
            <div class="col-md-12" *ngIf="this.arrayData.length > 0">
                <div class="hidden-mobile">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Farmacia</th>
                                <th scope="col" class="text-right">Nº de estrellas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="rowDataTable" *ngFor="let item of this.arrayData">
                                <ng-container *ngIf="item.activity_type === 'CANCEL_EXCHANGE'">
                                    <td>{{item.activity_date_string | date: 'dd/MM/yyyy'}}</td>
                                    <td>Cancelación de canje {{item.item_sd.es}}</td>
                                    <td></td>
                                    <td class="alincelds">
                                        <span class="positiveStar">{{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}</span>
                                        <img src="../../../assets/images/Group.png" />
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="item.activity_type === 'PURCHASE'">
                                    <td class="col-md-2">{{item.activity_date_string | date: 'dd/MM/yyyy'}}</td>
                                    <td class="col-md-4" *ngIf="item.description.es == 'Bonus'">{{item.offer_sd}}</td>
                                    <td class="col-md-3" *ngIf="item.description.es !== 'Bonus' && item.description.es !== ''">
                                        Compra producto
                                        <span *ngIf="item.classificationdata !== undefined && item.classificationdata.length > 0">
                                            {{item.classificationdata[0].description.es}}
                                        </span>
                                        {{item.amount}} €
                                    </td>
                                    <td class="col-md-3" *ngIf="item.description.es == ''">

                                    </td>
                                    <td class="col-md-3">{{item.where_ld}}</td>
                                    <td class="col-md-3" *ngIf="item.description.es == 'Bonus'" class="alincelds">
                                        <span class="positiveStar">{{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}</span>
                                        <img src="../../../assets/images/Group.png" />
                                    </td>
                                    <td class="col-md-3" *ngIf="item.description.es !== 'Bonus'" class="alincelds">
                                        <span class="positiveStar">{{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}</span>
                                        <img src="../../../assets/images/Group.png" />
                                    </td>
                                    <td class="col-md-3" *ngIf="item.description.es == ''">

                                    </td>
                                </ng-container>

                                <ng-container *ngIf="item.activity_type === 'EXCHANGE'">
                                    <td class="col-md-2">{{item.activity_date_string | date: 'dd/MM/yyyy'}}</td>
                                    <td class="col-md-4">Canje {{item.item_sd.es}}</td>
                                    <td class="col-md-3" *ngIf="item.where_ld !== 'naos_where'">{{item.where_ld}}</td>
                                    <td class="col-md-3" *ngIf="item.where_ld == 'naos_where'"></td>
                                    <td class="col-md-3" class="alincelds">
                                        <span class="negativeStar">{{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}</span>
                                        <img src="../../../assets/images/Group.png" />
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="hidden-desktop hidden-tablet display-mobile">
                    <div class="row my-4 rowDataTable" *ngFor="let item of this.arrayData">

                        <div class="row col-4 blue-text">
                            <div class="col-12">Fecha</div>
                            <div class="col-12">Descripción</div>
                            <div class="col-12">Farmacia</div>
                            <div class="col-12">Nº de estrellas</div>
                        </div>
                        <div class="row col-8">
                            <ng-container *ngIf="item.activity_type === 'CANCEL_EXCHANGE'">
                                <div class="col-12 ml-5">{{item.activity_date_string | date: 'dd/MM/yyyy'}}</div>
                                <div class="col-12 ml-5">Cancelación de canje {{item.item_sd.es}}</div>
                                <div class="col-12 ml-5"></div>
                                <div class="col-12 ml-5 positiveStar">
                                    <img src="../../../assets/images/Group.png" class="mr-2" />{{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}
                                </div>
                            </ng-container>

                            <ng-container *ngIf="item.activity_type === 'PURCHASE'">
                                <div class="col-12 ml-5">{{item.activity_date_string | date: 'dd/MM/yyyy'}}</div>
                                <div class="col-12 ml-5" *ngIf="item.description.es == 'Bonus'">{{item.offer_sd}}</div>
                                <div class="col-12 ml-5" *ngIf="item.description.es !== 'Bonus'">
                                    Compra producto
                                    <span *ngIf="item.classificationdata !== undefined && item.classificationdata.length > 0">{{item.classificationdata[0].description.es}}</span>
                                    {{item.amount}} €
                                </div>
                                <div class="col-12 ml-5">{{item.where_ld}}</div>
                                <div class="col-12 ml-5" *ngIf="item.description.es == 'Bonus'">
                                    <span class="positiveStar">{{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}</span>
                                    <img src="../../../assets/images/Group.png" class="mr-2" />
                                </div>
                                <div class="col-12 ml-5" *ngIf="item.description.es !== 'Bonus'">
                                    <span class="positiveStar">{{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}</span>
                                    <img src="../../../assets/images/Group.png" class="mr-2" />
                                </div>
                            </ng-container>

                            <ng-container *ngIf="item.activity_type === 'EXCHANGE'">
                                <div class="col-12 ml-5">{{item.activity_date_string | date: 'dd/MM/yyyy'}}</div>
                                <div class="col-12 ml-5">Canje {{item.item_sd.es}}</div>
                                <div class="col-12 ml-5" *ngIf="item.where_ld !== 'naos_where'">{{item.where_ld}}</div>
                                <div class="col-12 ml-5" *ngIf="item.where_ld == 'naos_where'"></div>
                                <div class="col-12 ml-5 negativeStar">
                                    {{this.showLoyaltyUnitPrize(item.loyalty_unit) | number:'1.0-3':'de'}}
                                    <img src="../../../assets/images/Group.png" class="mr-2" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationHistory" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        {{ this.textModal }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()"
                    class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
